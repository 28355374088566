











import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue'
import {BrokerCategory, BrokerRecord} from '@/interfaces';


@Component({
components: {
    Modal
  },
})
export default class BrokerCategoryTableHead extends Vue {
  
  @Prop(Object) readonly broker: BrokerRecord[] | undefined;

  @Prop(Object) readonly category: BrokerCategory | undefined;

}
