<template>
  <div>
    <div class="flex justify-between">
      <BackButton/>
    </div>
    <h2 class="text-center font-semibold text-lg my-2">
      404
      <span class="block text-gray-500 text-sm">Page Not Found</span>
    </h2>
  </div>
</template>

<script>
import title from '@/title';
import BackButton from '@/components/BackButton.vue'

export default {
  name: 'error-not-found',
  beforeRouteEnter: (to, from, next) => {
    document.title = title('404 Page Not Found');
    next();
  },
  components: {
    BackButton,
  }
}
</script>
