


































import { Component, Prop, Vue } from 'vue-property-decorator';
import ValueRow from '@/components/IndividualBroker/ValueRow.vue'
import {BrokerCategory, BrokerField, BrokerRecord} from '@/interfaces';
import BrokerFieldSource from "@/components/BrokerFieldSource.vue";
import BrokerFieldUpdated from "@/components/BrokerFieldUpdated.vue";
import BrokerFieldComment from "@/components/BrokerFieldComment.vue";

@Component({
components: {
    BrokerFieldSource,
    ValueRow,
    BrokerFieldUpdated,
    BrokerFieldComment
  },
})
export default class BrokerCategoryTableBody extends Vue {
  
  @Prop(Object) readonly broker: BrokerRecord | undefined;

  @Prop(Object) readonly category: BrokerCategory | undefined;

  @Prop(Number) readonly categoryIndex: number | undefined;

  private get fields(): BrokerField[] {
    return this.$store.getters.brokerFields(this.category?.slug);
  }
}
