





























import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue'
import {BrokerCategory, BrokerField, BrokerRecord, FieldData} from '@/interfaces';
import { BrokerFieldSlug } from '@/enums';
import MoreInfoButton from "@/components/MoreInfoButton.vue";

@Component({
components: {
    MoreInfoButton,
    Modal
  },
})
export default class ValueRow extends Vue {
  
  @Prop(Object) readonly broker: BrokerRecord | undefined;

  @Prop(Object) readonly category: BrokerCategory | undefined;

  @Prop(Object) readonly field: BrokerField | undefined;

  @Prop(Number) readonly numFields: number | undefined;

  @Prop(Number) readonly fieldIndex: number | undefined;

  @Prop(Boolean) readonly isMore: boolean | undefined;

  @Prop(Number) readonly categoryIndex: number | undefined;

  /**
   * @private
   */
  private get headerCategory(): BrokerCategory {
    return ( !this.fieldIndex && this.category ) ? this.category : {
      id: 0,
      slug: '',
      name:'',
      description: '',
    };
  }

  private get updatedAt(): string {
    return this.fieldData.updatedAt ? new Date(this.fieldData.updatedAt).toLocaleDateString() : '';
  }

  /**
   * @private
   */
  private get fieldTitle(): BrokerField {
    return (this.$store.getters.fieldRecord as Record<BrokerFieldSlug, BrokerField>)[this.field?.slug as BrokerFieldSlug];
  }

  /**
   * @private
   */
  private get fieldData(): FieldData {
    return this.broker?.fields[this.field?.slug as BrokerFieldSlug] ?? {
      value: 0,
      comment: "",
      sourceUrl: "",
      updatedAt: "",
      createdAt: "",
      html: "",
      numericSortValue: 0,
      alphaSortValue: "",
    }
  }

  /**
   * @private
   */
  private get isMoreInfo(): boolean {
    return this.field?.slug ? this.$store.state.individualBroker.moreInfo[this.field?.slug] : false;
  }

  /**
   * @private
   */
  private toggleMoreInfo() {
    this.$store.commit('toggleIndividualBrokerMoreInfo', this.field?.slug);
  }
}
