




























































import { Component, Vue } from 'vue-property-decorator';
import TableHead from '@/components/IndividualBroker/TableHead.vue'
import BackButton from '@/components/BackButton.vue'
import TableBody from '@/components/IndividualBroker/TableBody.vue'
import {BrokerCategory, BrokerRecord} from '@/interfaces';
import ShareButton from "@/components/ShareButton.vue";

@Component({
components: {
    TableHead,
    TableBody,
    BackButton,
    ShareButton
  },
})
export default class Broker extends Vue {
  
  private get categories(): BrokerCategory[] {
    return this.$store.state.broker.categories;
  }

  private get broker(): BrokerRecord | undefined {
    return this.$store.state.broker.brokers.find((b: BrokerRecord) => b.broker.slug === this.$route.params.slug);
  }

  private get openAccountText(): string {
    const name = this.broker?.broker.name || '';

    const isVowel = ['a', 'e', 'i', 'u'].includes(name.charAt(0).toLowerCase());

    return `Open ${isVowel ? 'an': 'a'} ${name} Account`;
  }

  /**
   * @private
   */
  private get shareData(): ShareData {
    return {
      text: `View ${this.broker?.broker.name} broker details on CryptoBrokerCompare.com.`,
      title: `${this.broker?.broker.name} Broker Details`,
      url: document.URL
    }
  }
}
