<template>
  <div>
    <Broker/>
  </div>
</template>

<script>
import Broker from '@/components/IndividualBroker/Broker.vue'
import store from '@/store';
import title from '@/title';
import data from '@/data';
import meta from '@/meta';

export default {
  name: 'individual-broker',
  beforeRouteEnter: (to, from, next) => {
    store.commit('setSlug', to.params.slug);

    // Wait for data to load
    data.then(() => {

      // If the broker slug is invalid
      if (!store.getters.individualBroker) {
        next('/404');
      }

      store.commit('replaceBackButtonText', `Back to ${store.getters.individualBroker.broker.name}`);

      meta({
        title: title(store.getters.individualBroker.broker.name),
        description: `View ${store.getters.individualBroker.broker.name} maker & taker fees, spreads, and other details.`,
        url: to.path,
        keywords: [store.getters.individualBroker.broker.name, 'maker', 'taker', 'spread', 'commission', 'deposit', 'withdrawal', 'fees', 'exchange', 'broker'],
      });

      next();
    });
  },
  components: {
    Broker,
  }
}
</script>